import axios from '@/axios.js'

export default {
    getCampaignsCategories: async (filters = [],  per_page) => {
        let query = ""
        if (filters.length > 0) {
        query += ',' + filters.map(filter => {
            return `${filter.field}:${filter.value}`
        }).join(',')
        }
        try {
            const response = await axios.editorapi.get(`v4/campaigns/categories/`, { params: {
                q: query,
                per_page: per_page
            }
            })
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    sortCategories: async (resortUuid, categoriesOrdered) => {
        try {
            const response = await axios.editorapi.patch(`v4/campaigns/categories/`, { 'application_uuid': resortUuid , 'uuids':categoriesOrdered })
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    deleteCategory: async (categoryUuid,applicationUuid) => {
        try {            
          await axios.editorapi.delete(`v4/campaigns/categories/${categoryUuid}`,{ data: {'application_uuid': applicationUuid} })
        } catch (error) {
          throw new Error(error)
        }
    },
    saveCategory: async (uuid,data) => {
        try {            
          await axios.editorapi.post(`v4/campaigns/categories/${uuid}`,data)
        } catch (error) {
          throw new Error(error)
        }
    },
    updateCategory: async (uuid,data) => {
        try {            
          await axios.editorapi.patch(`v4/campaigns/categories/${uuid}`,data)
        } catch (error) {
          throw new Error(error)
        }
    },
   
   
  
}

