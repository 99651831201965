<template>
  <div class="px-8">
    <h3>{{ this.$t('apps.campaigns.section_users_title') }}</h3>
    <div class="datatable">
      <Datatable
        :config="datatableConfig"
        :elements="installations"
        :stripe="true"
        :sst="true"
        @checkbox="handleCheckbox"
        :pagination="pagination"
        pagination-position="top"
        @filter="handleFilter"
      />
      <div class="p-2" v-if="installations.length === 0">{{ this.$t('apps.campaigns.section_users_no_users_placeholder') }}</div>
    </div>
    <div class="flex flex-row justify-between mt-4">
      <vs-button color="primary" type="border" icon="cancel" class="rounded-lg" @click="handleCancel">{{ this.$t('apps.campaigns.section_users_button_cancel') }}</vs-button>
      <vs-button color="primary" type="filled" icon="add" class="rounded-lg" @click="handleAddInstallations">{{ this.$t('apps.campaigns.section_users_button_confirm') }}</vs-button>
    </div>
  </div>

</template>

<script>
import Datatable from '@/modules/Shared/Components/table/SKDataTable.vue'
import CampaignsService from '@/modules/Apps/Services/CampaignsService'
import {fromDBToTimezone} from "@/modules/Shared/Helpers/dateHelper";
import {mapGetters} from "vuex";
import {DateTime} from "luxon";

export default {
    name: 'installations-datatable',
    components: {
      Datatable
    },
    props: {
      selectedInstallations: { type: Array,  default: () => [] }
    },
    data () {
      return {
        allSelectedInstallations: [],
        installations: [],
        pagination: {},
      }
    },
    async created() {
      this.allSelectedInstallations = this.selectedInstallations

      await this.getInstallations([], 1)
    },
    computed: {
      ...mapGetters(['AppActiveUser']),
      allSelectedInstallationsUuids() {
        return this.allSelectedInstallations.map(installation => installation.uuid)
      },
      datatableConfig() {
        return [
          { 'field': 'selected', head: '', type: 'check', 'searchable': false, 'sortable': false },
          { 'field': 'username', head: this.$t('apps.campaigns.section_users_table_username'), 'searchable': true, 'sortable': false },
          { 'field': 'language', head: this.$t('apps.campaigns.section_users_table_language'), 'searchable': false, 'sortable': false },
          { 'field': 'device', head: this.$t('apps.campaigns.section_users_table_device'), 'searchable': false, 'sortable': false },
          { 'field': 'updated_at', head: this.$t('apps.campaigns.section_users_table_updated_at'), 'searchable': false, 'sortable': false },
        ]
      },
    },
    methods: {
      async getInstallations(filters, page) {
        const installationsResponse = await CampaignsService.getInstallations(
          [...filters,
            {
              field: 'only_registered',
              value: true
            },
            {
              field: 'all_user_installations',
              value: true
            },
          ],
            page
        );

        this.installations = installationsResponse.data.map(this.mapInstallationToRow)

        this.pagination = {
          per_page: installationsResponse.per_page,
          total: installationsResponse.total
        }
      },
      async handleFilter({page, filters}) {
        await this.getInstallations(filters, page)
      },
      mapInstallationToRow(installation) {
        return {
          uuid: installation.uuid,
          selected: this.allSelectedInstallations.map(installation => installation.uuid).includes(installation.uuid),
          username: installation.user ? installation.user.username : null,
          language: installation.user ? installation.user.language : null,
          device: installation.device.model,
          updated_at: fromDBToTimezone(installation.updated_at, this.AppActiveUser.timezone).toLocaleString(DateTime.DATETIME_SHORT)
        }
      },
      handleCancel() {
        this.$emit('cancel');
      },
      handleAddInstallations() {
        this.$emit('commit', this.allSelectedInstallations);
      },
      handleCheckbox(uuid, value, __field) {
        if (value) {
          if(!this.allSelectedInstallationsUuids.includes(uuid)) {
            const installationToAdd = this.installations.find(installation => installation.uuid === uuid)
            this.allSelectedInstallations = [...this.allSelectedInstallations, {
              uuid,
              username: installationToAdd.username,
              device: installationToAdd.device,
            }]
          }
        } else {
          this.allSelectedInstallations = this.allSelectedInstallations.filter(installation => installation.uuid !== uuid)
        }

      }
    }
}
</script>

<style scoped>
  .datatable {
    height: calc(100vh - 220px);
    overflow-y: scroll;
  }
</style>
