var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h5", { staticClass: "mb-4" }, [
        _vm._v(_vm._s(this.$t("apps.campaigns.section_segmentation_title")))
      ]),
      _c(
        "vs-checkbox",
        {
          staticClass: "ml-0 mb-2",
          attrs: { disabled: _vm.campaign_edit_mode },
          model: {
            value: _vm.selectUsers,
            callback: function($$v) {
              _vm.selectUsers = $$v
            },
            expression: "selectUsers"
          }
        },
        [
          _vm._v(
            _vm._s(
              this.$t(
                "apps.campaigns.section_segmentation_select_users_checkbox"
              )
            )
          )
        ]
      ),
      !_vm.selectUsers
        ? _c(
            "div",
            { staticClass: "vx-row pl-4" },
            [
              _c("v-select", {
                staticClass: "w-1/4",
                attrs: {
                  multiple: "",
                  options: _vm.campaign_form_languages,
                  disabled: _vm.campaign_edit_mode,
                  placeholder: this.$t(
                    "apps.campaigns.section_segmentation_select_languages_placeholder"
                  )
                },
                model: {
                  value: _vm.selectedLanguages,
                  callback: function($$v) {
                    _vm.selectedLanguages = $$v
                  },
                  expression: "selectedLanguages"
                }
              }),
              _c("v-select", {
                staticClass: "w-1/4 ml-2",
                attrs: {
                  clearable: false,
                  options: _vm.isSkiApp
                    ? _vm.$store.getters[
                        "apps/campaign_registration_selection_options"
                      ](_vm.resorts)
                    : _vm.$store.getters[
                        "apps/campaign_registration_selection_options"
                      ](_vm.resorts).filter(function(option) {
                        return option.code !== "only_registered"
                      }),
                  disabled: _vm.campaign_edit_mode
                },
                model: {
                  value: _vm.selectedRegistration,
                  callback: function($$v) {
                    _vm.selectedRegistration = $$v
                  },
                  expression: "selectedRegistration"
                }
              }),
              _vm.selectedRegistration.code === "favorite_resort" &&
              _vm.resorts.length > 1
                ? _c("v-select", {
                    staticClass: "w-1/4 ml-2",
                    attrs: {
                      clearable: false,
                      options: _vm.resorts,
                      disabled: _vm.campaign_edit_mode
                    },
                    model: {
                      value: _vm.selectedResort,
                      callback: function($$v) {
                        _vm.selectedResort = $$v
                      },
                      expression: "selectedResort"
                    }
                  })
                : _vm._e(),
              _vm.hasNotificationsAllowed
                ? _c("v-select", {
                    key: _vm.availableCategories.length,
                    staticClass: "w-1/4 ml-2",
                    attrs: {
                      clearable: false,
                      options: _vm.availableCategories,
                      placeholder:
                        this.$t(
                          "apps.campaigns.section_segmentation_select_category_placeholder"
                        ) + " *",
                      required: !_vm.selectedCategory
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "option",
                          fn: function(option) {
                            return [
                              option.type === "create"
                                ? _c("li", [
                                    _c(
                                      "span",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.createNew()
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(option.text) + " ")]
                                    )
                                  ])
                                : _c("li", [_vm._v(_vm._s(option.label))])
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3001729708
                    ),
                    model: {
                      value: _vm.selectedCategory,
                      callback: function($$v) {
                        _vm.selectedCategory = $$v
                      },
                      expression: "selectedCategory"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _c("div", { staticClass: "pl-4" }, [
            _c(
              "div",
              { staticClass: "vx-row" },
              [
                !_vm.campaign_edit_mode &&
                !_vm.campaign_selected_installations.length
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "rounded-lg",
                        attrs: {
                          color: "primary",
                          type: "border",
                          icon: "add"
                        },
                        on: {
                          click: function($event) {
                            _vm.isModalOpen = true
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              this.$t(
                                "apps.campaigns.section_segmentation_select_users_add_button"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e(),
                !_vm.campaign_edit_mode &&
                _vm.campaign_selected_installations.length
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "rounded-lg",
                        attrs: { color: "primary", type: "border" },
                        on: {
                          click: function($event) {
                            _vm.isModalOpen = true
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              this.$t(
                                "apps.campaigns.section_segmentation_select_users_updated_button"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.campaign_selected_installations.length &&
                !_vm.campaign_edit_mode
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "rounded-lg ml-4",
                        attrs: { color: "danger", type: "border" },
                        on: { click: _vm.clearCampaignInstallations }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              this.$t(
                                "apps.campaigns.section_segmentation_select_users_clear_button"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _vm.campaign_selected_installations
              ? _c(
                  "div",
                  { staticClass: "vx-row mt-4" },
                  _vm._l(_vm.campaign_selected_installations, function(
                    selectedInstallation
                  ) {
                    return _c(
                      "div",
                      {
                        key: selectedInstallation.uuid,
                        class: [
                          {
                            "cursor-default": _vm.campaign_edit_mode,
                            "cursor-pointer": !_vm.campaign_edit_mode
                          },
                          "w-1/6",
                          "p-3",
                          "mr-2",
                          "mb-2",
                          "flex",
                          "flex-row",
                          "items-center",
                          "justify-between",
                          "bg-white",
                          "border-solid",
                          "border-grey-light",
                          "border-1",
                          "rounded"
                        ],
                        on: {
                          click: function($event) {
                            return _vm.handleRemoveInstallation(
                              selectedInstallation.uuid
                            )
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "flex flex-column" }, [
                          _c("span", [
                            _vm._v(_vm._s(selectedInstallation.username))
                          ]),
                          _c(
                            "span",
                            { staticClass: "text-xs text-grey font-semibold" },
                            [_vm._v(_vm._s(selectedInstallation.device))]
                          )
                        ]),
                        !_vm.campaign_edit_mode
                          ? _c("span", [_vm._v("✕")])
                          : _vm._e()
                      ]
                    )
                  }),
                  0
                )
              : _vm._e()
          ]),
      _c(
        "div",
        { staticClass: "modalUsers" },
        [
          _c(
            "vs-popup",
            {
              attrs: {
                "button-close-hidden": "",
                fullscreen: "",
                active: _vm.isModalOpen,
                title: ""
              },
              on: {
                "update:active": function($event) {
                  _vm.isModalOpen = $event
                }
              }
            },
            [
              _vm.isModalOpen
                ? _c("InstallationsDatatable", {
                    attrs: {
                      "selected-installations":
                        _vm.campaign_selected_installations
                    },
                    on: {
                      cancel: function($event) {
                        _vm.isModalOpen = false
                      },
                      commit: _vm.handleInstallationsCommit
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm.isCreateModalOpen
        ? _c(
            "div",
            [
              _c("CreateOrEditCategoryForm", {
                attrs: {
                  selectedCategory: _vm.newCategory,
                  languages: _vm.languages,
                  isNew: _vm.isNew,
                  aplicationUuid: _vm.aplicationUuid,
                  isModalOpen: _vm.isCreateModalOpen
                },
                on: {
                  updateList: function($event) {
                    return _vm.updateList()
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }