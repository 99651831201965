<template>
  <div class="mt-4 bg-white p-8">
    <section class="mb-8">
      <CampaignSegmentation
        :hasNotificationsAllowed="hasNotificationsAllowed"
        :hasDRMCampaignCategoriesAllowed="hasDRMCampaignCategoriesAllowed"
        :isSkiApp="isSkiApp"
      />
    </section>
    <section class="mb-8">
      <CampaignCallToAction
        :isSkiApp="isSkiApp"
      />
    </section>

    <section class="mb-8">
      <CampaignContents/>
    </section>

    <section class="mb-8">
      <CampaignSchedule />
    </section>

    <section class="mb-8 flex flex-row justify-between items-center">
      <vs-checkbox v-model="hide">{{ this.$t('apps.campaigns.section_submit_checkbox_hide') }}</vs-checkbox>
      <div class="flex flex-row items-baseline">
        <p>{{ this.$t('apps.campaigns.section_submit_device_count') }} {{campaign_total_installations_count}} {{ this.$t('apps.campaigns.section_content_devices') }}</p>

        <vs-button
          v-if="campaign_edit_mode"
          @click="editCampaign"
          :disabled="!campaign_is_valid(hasNotificationsAllowed)"
          class="rounded-lg ml-4"
          color="primary"
          type="filled"
        >
          {{ this.$t('apps.campaigns.section_submit_button_save') }}
        </vs-button>

        <vs-button
          v-else
          @click="scheduleCampaign"
          :disabled="!campaign_is_valid(hasNotificationsAllowed)"
          class="rounded-lg ml-4"
          color="primary"
          type="filled"
          icon="send"
          :icon-after="true"
        >
          {{ this.campaign_send_now
            ? this.$t('apps.campaigns.section_submit_button_send')
            : this.$t('apps.campaigns.section_submit_button_program')
          }}
        </vs-button>
      </div>
    </section>
  </div>
</template>

<script>

import CampaignSegmentation from '@/modules/Apps/Components/campaigns/form/CampaignSegmentation'
import CampaignCallToAction from "@/modules/Apps/Components/campaigns/form/CampaignCallToAction";
import CampaignContents from "@/modules/Apps/Components/campaigns/form/CampaignContents";
import CampaignSchedule from "@/modules/Apps/Components/campaigns/form/CampaignSchedule";
import CampaignsService from "@/modules/Apps/Services/CampaignsService";
import notifications from "@/modules/Shared/Mixins/notifications";
import loader from "@/modules/Shared/Mixins/loader";

import {mapGetters} from "vuex";
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'campaign-form',
  components: {
    CampaignSchedule,
    CampaignContents,
    CampaignSegmentation,
    CampaignCallToAction,
  },
  mixins: [ notifications, loader ],
  computed: {
    ...mapGetters('apps', ['campaign_total_installations_count', 'campaign_is_valid', 'campaign_payloads', 'campaign_edit_mode', 'campaign_send_now']),
    hide: {
      get() { return this.$store.state.apps.campaigns.selection.hide },
      set(value) {
        this.$store.commit('apps/SET_CAMPAIGN_HIDE', value)
      }
    }
  },
  props: {
      hasDRMCampaignCategoriesAllowed: {
          type: Boolean
      },
      hasNotificationsAllowed: {
          type: Boolean,
      },
      isSkiApp: {
          type: Boolean
      },
  },
  methods: {
    async scheduleCampaign() {
      this.$vs.dialog({
        type:'confirm',
        color:'danger',
        title: this.$t('apps.campaigns.section_submit_program_dialog_title'),
        text: `${this.$t('apps.campaigns.section_submit_program_dialog_body')} ${this.campaign_total_installations_count} ${this.$t('apps.campaigns.section_content_devices')}`,
        accept: async () => {
          for await (const payload of this.campaign_payloads) {
            await CampaignsService.scheduleCampaign(uuidv4(), payload)
          }
          this.$emit('campaign-scheduled')
        }
      })
    },

    async editCampaign() {
      await CampaignsService.editCampaign(this.$route.params.campaignUuid, this.campaign_payloads[0])
      this.$emit('campaign-scheduled')
    }
  }
}
</script>
