var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.campaign_is_segmented_by_selected_users ||
    _vm.campaign_selected_languages.length
    ? _c("div", [
        _c("h5", { staticClass: "mb-0" }, [
          _vm._v(_vm._s(this.$t("apps.campaigns.section_content_title")))
        ]),
        _vm.campaign_selected_languages.length
          ? _c(
              "div",
              { staticClass: "flex flex-row flex-wrap" },
              _vm._l(_vm.campaign_selected_languages, function(language) {
                return _c("CampaignContent", {
                  key: language.acronym,
                  attrs: { language: language }
                })
              }),
              1
            )
          : _c(
              "div",
              { staticClass: "vx-row pl-4" },
              [_c("CampaignContent")],
              1
            )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }