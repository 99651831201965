<template>
  <div>
    <h5 class="mb-4">{{ this.$t('apps.campaigns.section_cta_title') }}</h5>
    <div class="vx-row pl-4">
      <v-select class="w-1/4"
                :clearable="false"
                v-model="selectedCta"
                :options="isSkiApp ? campaigns.form_options.cta : campaigns.form_options.cta.filter(option => option.code !== 'challenge')"
      />
      <v-select class="w-1/4 ml-2"
                :clearable="false"
                v-if="selectedCta.code === 'challenge'"
                v-model="selectedChallenge"
                :options="challenges"
      />
    </div>
  </div>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import CampaignsService from "@/modules/Apps/Services/CampaignsService";

export default {
    name: 'campaign-call-to-action',
    data() {
      return {
        challenges: [],
      }
    },
    props: {
      isSkiApp: {
        type: Boolean
      },
    },
    async created() {
      const challenges = await CampaignsService.getChallenges(this.AppActiveUser.language)
      this.challenges = challenges.map(challenge => ({ code: challenge.uuid, label: challenge.title }))
    },
    computed: {
      selectedCta: {
        get() { return this.$store.state.apps.campaigns.selection.cta },
        async set(value) {
          if (value.code === 'url') {
            await this.$store.dispatch('apps/addUrlToCampaignContents')
            this.$store.commit('apps/SET_CAMPAIGN_CHALLENGE', null)
          } else {
            if (value.code === 'challenge') {
              this.$store.commit('apps/SET_CAMPAIGN_CHALLENGE', this.challenges[0])
            } else {
              this.$store.commit('apps/SET_CAMPAIGN_CHALLENGE', null)
            }

            await this.$store.dispatch('apps/removeUrlFromCampaignContents')
          }
          this.$store.commit('apps/SET_CAMPAIGN_CTA', value)
        }
      },
      selectedChallenge: {
        get() { return this.$store.state.apps.campaigns.selection.challenge },
        async set(value) {
          this.$store.commit('apps/SET_CAMPAIGN_CHALLENGE', value)
        }
      },
      ...mapState('apps', [
        'campaigns',
      ]),
      ...mapGetters(['AppActiveUser'])
    }
}
</script>
