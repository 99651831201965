<template>
  <div>
    <h5 class="mb-4">{{ this.$t('apps.campaigns.section_schedule_title') }}</h5>
    <ul>
      <li class="mt-2">
        <vs-radio v-model="sendNow" vs-name="schedule" :vs-value="true">{{ this.$t('apps.campaigns.section_schedule_radio_now') }}</vs-radio>
      </li>
      <li class="mt-2">
        <vs-radio v-model="sendNow" vs-name="schedule" :vs-value="false">{{ this.$t('apps.campaigns.section_schedule_radio_program') }}</vs-radio>
      </li>
    </ul>
    <div v-if="!sendNow">
      <flat-pickr
        :placeholder="this.$t('apps.campaigns.section_schedule_datepicker_placeholder')"
        v-model="scheduleDate"
        :config="{
            wrap: false,
            enableTime: true,
            time_24hr: true,
            defaultDate: DateTime.fromObject({zone: this.AppActiveUser.timezone}).toISO(),
            minDate: DateTime.fromObject({zone: this.AppActiveUser.timezone}).toISO(),
            minuteIncrement: 1
          }"
      />
      <p class="mt-2 text-sm">{{ this.$t('apps.campaigns.section_schedule_timezone') }} {{this.AppActiveUser.timezone}}</p>
    </div>

    <div class="mt-8">
      <h5 class="mb-4">{{ this.$t('apps.campaigns.section_expiration_date_title') }} <vx-tooltip :text="this.$t('apps.campaigns.section_expiration_date_info_text')" position="top" class="ml-0 checkbox__tooltip cursor-pointer">
        <vs-icon icon="help" round bg="white" color="primary"></vs-icon>
      </vx-tooltip>
      </h5>

      <div>
        <v-select class="w-1/4"
                  :clearable="false"
                  v-model="selectedTtlOption"
                  :options="campaigns.form_options.ttl"
        />

        <div v-if="selectedTtlOption.code === 'custom'">
          <flat-pickr
            class="mt-2"
            @input="handleExpirationDateChange"
            :value="this.$store.getters['apps/campaign_expiration_date']"
            :config="{
              wrap: false,
              enableTime: true,
              time_24hr: true,
              minDate: minCalendarExpiryDate,
              maxDate: maxCalendarExpiryDate,
              hourIncrement: 1
            }"
          />
          <p class="mt-2 text-sm">{{ this.$t('apps.campaigns.section_schedule_timezone') }} {{this.AppActiveUser.timezone}}</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import flatPickr from 'vue-flatpickr-component';
import {mapGetters, mapState} from "vuex";
import {getTimezoneOffset} from "@/modules/Shared/Helpers/dateHelper";
import {DateTime} from "luxon";

export default {
  name: 'campaign-schedule',
  components: {
    flatPickr
  },
  computed: {
      DateTime() {
          return DateTime
      },
    ...mapState('apps', [
      'campaigns',
    ]),
    ...mapGetters(['AppActiveUser']),
    sendNow: {
      get() { return this.$store.state.apps.campaigns.selection.send_now },
      set(value) {
        this.$store.commit('apps/SET_CAMPAIGN_SEND_NOW', value)
        this.$store.commit('apps/SET_CAMPAIGN_SCHEDULE_DATE', null)
      }
    },
    scheduleDate: {
      get() { return this.$store.state.apps.campaigns.schedule_date },
      set(value) {
        this.$store.commit(
          'apps/SET_CAMPAIGN_SCHEDULE_DATE',
          this.formatDatetimeStringWithTimezone(value)
        )
      }
    },
    selectedTtlOption: {
      get() { return this.$store.state.apps.campaigns.selection.ttl_option },
      async set(value) {
        this.$store.commit('apps/SET_CAMPAIGN_TTL_OPTION', value)
        this.$store.commit('apps/SET_CAMPAIGN_TTL', value.code !== 'custom' ? value.code : 720)
      }
    },
    minCalendarExpiryDate() {
      const scheduleDate = this.$store.state.apps.campaigns.schedule_date
        ? new Date(this.$store.state.apps.campaigns.schedule_date)
        : new Date()
      const minExpirationDate = new Date()

      return minExpirationDate.setTime(scheduleDate.getTime() + 2 * 60 * 60 * 1000)
    },
    maxCalendarExpiryDate() {
      const scheduleDate = this.$store.state.apps.campaigns.schedule_date
        ? new Date(this.$store.state.apps.campaigns.schedule_date)
        : new Date()
      const maxExpirationDate = new Date()

      return maxExpirationDate.setTime(scheduleDate.getTime() + 720 * 60 * 60 * 1000)
    }
  },
  methods: {
    formatDatetimeStringWithTimezone(datetimeString) {
      // 2022-07-31 05:00 -> 2022-07-31T05:00:00.000-05:00
      const offset = getTimezoneOffset(this.AppActiveUser.timezone)
      return datetimeString.replace(' ', 'T') + `:00.000${offset}`
    },
    handleExpirationDateChange(value) {
      const scheduleDate = this.$store.state.apps.campaigns.schedule_date
        ? new Date(this.$store.state.apps.campaigns.schedule_date)
        : new Date()

      const expirationDate = new Date(value)

      const ttl = Math.round(Math.abs(expirationDate - scheduleDate) / 36e5)

      this.$store.commit('apps/SET_CAMPAIGN_TTL', ttl)
    }
  }
}
</script>
