<template>
  <div class="language_content relative mr-4 p-6 mt-4 border border-8 border-grey rounded">
    <div v-if="language">
      <div class="flex flex-row justify-between items-baseline">
        <h6>{{ language.name }}</h6>
        <p class="text-right text-sm">{{content.count}} {{ this.$t('apps.campaigns.section_content_devices') }}</p>
      </div>
      <p class="text-sm" v-if="language.is_default">{{ this.$t('apps.campaigns.section_content_default_language') }}</p>
      <div v-else>
        <vs-checkbox
          v-if="this.campaign_languages_is_default_selected"
          class="ml-0 mb-4 text-sm"
          v-model="useDefaultLanguageContent"
        >
          {{ this.$t('apps.campaigns.section_content_checkbox_default_language') }}
        </vs-checkbox>
      </div>
    </div>
    <p v-else class="text-right text-sm">{{content? content.count : 0}} {{ this.$t('apps.campaigns.section_content_devices') }}</p>

    <!--Title-->
    <vs-textarea
      :disabled="useDefaultLanguageContent"
      :class="{'bg-grey-light': useDefaultLanguageContent}"
      class="mb-2"
      v-model="title"
      :label="this.$t('apps.campaigns.section_content_input_title')"
    />
    <p v-if="errorBag.title && !useDefaultLanguageContent" class="text-danger text-xs">{{ errorBag.title }}</p>
    <p class="text-right text-xs">
      <span
        :class="{
          'text-danger': titleLength > maxTitleLength,
          'text-success': titleLength <= maxTitleLength,
        }"
      >{{ titleLength }}</span>
      /{{ maxTitleLength }} {{ this.$t('apps.campaigns.section_content_maximum_recommended_characters') }}
    </p>

    <!--Message-->
    <vs-textarea
      :disabled="useDefaultLanguageContent"
      :class="{'bg-grey-light': useDefaultLanguageContent}"
      class="mb-2"
      v-model="message"
      :label="this.$t('apps.campaigns.section_content_input_message')"
      rows="3"
    />
    <p v-if="errorBag.message && !useDefaultLanguageContent" class="text-danger text-xs">{{ errorBag.message }}</p>
    <p class="text-right text-xs">
      <span
        :class="{
          'text-danger': messageLength > maxMessageLength,
          'text-success': messageLength <= maxMessageLength,
        }"
      >{{ messageLength }}</span>
      /{{ maxMessageLength }} {{ this.$t('apps.campaigns.section_content_maximum_recommended_characters') }}
    </p>

    <!--Url-->
    <vs-input
      v-if="content && content.hasOwnProperty('url')"
      :disabled="useDefaultLanguageContent"
      :class="{'bg-grey-light': useDefaultLanguageContent}"
      class="w-full mb-2"
      type="url"
      v-model="url"
      :label="this.$t('apps.campaigns.section_content_input_url')"
    />
    <p v-if="errorBag.url && !useDefaultLanguageContent" class="text-danger text-xs">{{ errorBag.url }}</p>

    <!--Image-->
    <ImageForm
      v-if="content && content.hasOwnProperty('image')"
      @updateImage="handleUpdateImage"
      class="w-full mt-4"
      :id="`image-${this.languageAcronym}`"
      :valid-sizes="[
        { width: '960', height: '740' }
      ]"
      validationWeight="500"
      :validationMessage="this.$t('apps.campaigns.section_content_validation_image_error')"
      constraintsText="Jpg 960x740px (max. 500Kb)"
      height="250"
      :src="image"
      :disabled="useDefaultLanguageContent"
      />
  </div>
</template>

<script>

import ImageForm from '@/modules/Shared/Components/form/ImageForm'
import {urlToBase64} from "@/modules/Shared/Helpers/fileHelper";
import {mapGetters} from "vuex";

export default {
    name: 'campaign-content',
    components: {
      ImageForm
    },
    props: {
      language: { type: Object,  required: false, default: null }
    },
    data () {
      return {
        maxTitleLength: 50,
        maxMessageLength: 115,
        errorBag: {
          title: null,
          message: null,
          url: null
        }
      }
    },
    created() {
      this.title = this.content.title
      this.message = this.content.message
      if (this.content.hasOwnProperty('url')) {
        this.url = this.content.url
      }
      this.errorBag = {
        title: null,
        message: null,
        url: null
      }
    },
    methods: {
      setIsValid() {
        const isValid = Object.entries(this.errorBag).filter(([__key, value]) => value !== null).length === 0;
        this.$store.dispatch('apps/setCampaignContentField', {
          acronym: this.languageAcronym,
          fieldName: 'is_valid',
          fieldValue: isValid
        })
      },

      validateTitle(title) {
        this.errorBag.title = title.length === 0 ? `${this.$t('apps.campaigns.section_content_input_title')} ${this.$t('apps.campaigns.section_content_validation_cannot_be_empty')}` : null
        this.setIsValid()
      },

      validateMessage(message) {
        this.errorBag.message = message.length === 0 ? `${this.$t('apps.campaigns.section_content_input_message')} ${this.$t('apps.campaigns.section_content_validation_cannot_be_empty')}` : null
        this.setIsValid()
      },

      validateUrl(url) {
        if (url.length === 0) {
          this.errorBag.url = `URL ${this.$t('apps.campaigns.section_content_validation_cannot_be_empty')}`
        } else {
          try {
            new URL(url);
            this.errorBag.url = null
          } catch (e) {
            this.errorBag.url = `${this.$t('apps.campaigns.section_content_validation_invalid')} URL`
          }
        }

        this.setIsValid()
      },

      async handleUpdateImage(file) {
        let image = null

        if (file) {
          const imageUrl = URL.createObjectURL(file)
          const base64 = await urlToBase64(imageUrl)

          image = {
            url: imageUrl,
            content: base64
          }
        }
        await this.$store.dispatch('apps/setCampaignContentField', {
          acronym: this.languageAcronym,
          fieldName: 'image',
          fieldValue: image
        })
      }
    },
    watch: {
      '$store.state.apps.campaigns.selection.cta': function() {
        if (this.$store.state.apps.campaigns.selection.cta.code !== 'url') {
          this.errorBag.url = null
          this.setIsValid()
        }
      }
    },
    computed: {
      ...mapGetters('apps', ['campaign_languages_is_default_selected']),
      languageAcronym() {
        return this.language ? this.language.acronym : null
      },
      content() {
        return this.$store.getters["apps/campaign_language_content"](this.languageAcronym)
      },

      useDefaultLanguageContent: {
        get() { return this.content ? this.content.use_default_language_content : false },
        set(value) {
          this.$store.dispatch('apps/setCampaignContentField', {
            acronym: this.languageAcronym,
            fieldName: 'use_default_language_content',
            fieldValue: value
          })
        }
      },
      title: {
        get() {
          return this.content ? this.content.title : ''
        },
        set(value) {
          this.validateTitle(value)

          this.$store.dispatch('apps/setCampaignContentField', {
            acronym: this.languageAcronym,
            fieldName: 'title',
            fieldValue: value
          })
        }
      },
      message: {
        get() {
          return this.content ? this.content.message : ''
        },
        set(value) {
          this.validateMessage(value)

          this.$store.dispatch('apps/setCampaignContentField', {
            acronym: this.languageAcronym,
            fieldName: 'message',
            fieldValue: value
          })
        }
      },
      url: {
        get() {
          return this.content ? this.content.url : ''
        },
        set(value) {
          this.validateUrl(value)

          this.$store.dispatch('apps/setCampaignContentField', {
            acronym: this.languageAcronym,
            fieldName: 'url',
            fieldValue: value
          })
        }
      },
      image() {
        return this.content && this.content.image ? this.content.image.url : ''
      },
      titleLength() {
        return this.title.length
      },
      messageLength() {
        return this.message.length
      }
    },
}
</script>

<style scoped>
  .language_content {
    min-width: 370px;
    width: 370px;
  }
</style>
