var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "px-8" }, [
    _c("h3", [_vm._v(_vm._s(this.$t("apps.campaigns.section_users_title")))]),
    _c(
      "div",
      { staticClass: "datatable" },
      [
        _c("Datatable", {
          attrs: {
            config: _vm.datatableConfig,
            elements: _vm.installations,
            stripe: true,
            sst: true,
            pagination: _vm.pagination,
            "pagination-position": "top"
          },
          on: { checkbox: _vm.handleCheckbox, filter: _vm.handleFilter }
        }),
        _vm.installations.length === 0
          ? _c("div", { staticClass: "p-2" }, [
              _vm._v(
                _vm._s(
                  this.$t("apps.campaigns.section_users_no_users_placeholder")
                )
              )
            ])
          : _vm._e()
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "flex flex-row justify-between mt-4" },
      [
        _c(
          "vs-button",
          {
            staticClass: "rounded-lg",
            attrs: { color: "primary", type: "border", icon: "cancel" },
            on: { click: _vm.handleCancel }
          },
          [
            _vm._v(
              _vm._s(this.$t("apps.campaigns.section_users_button_cancel"))
            )
          ]
        ),
        _c(
          "vs-button",
          {
            staticClass: "rounded-lg",
            attrs: { color: "primary", type: "filled", icon: "add" },
            on: { click: _vm.handleAddInstallations }
          },
          [
            _vm._v(
              _vm._s(this.$t("apps.campaigns.section_users_button_confirm"))
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }