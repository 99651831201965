var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mt-4 bg-white p-8" }, [
    _c(
      "section",
      { staticClass: "mb-8" },
      [
        _c("CampaignSegmentation", {
          attrs: {
            hasNotificationsAllowed: _vm.hasNotificationsAllowed,
            hasDRMCampaignCategoriesAllowed:
              _vm.hasDRMCampaignCategoriesAllowed,
            isSkiApp: _vm.isSkiApp
          }
        })
      ],
      1
    ),
    _c(
      "section",
      { staticClass: "mb-8" },
      [_c("CampaignCallToAction", { attrs: { isSkiApp: _vm.isSkiApp } })],
      1
    ),
    _c("section", { staticClass: "mb-8" }, [_c("CampaignContents")], 1),
    _c("section", { staticClass: "mb-8" }, [_c("CampaignSchedule")], 1),
    _c(
      "section",
      { staticClass: "mb-8 flex flex-row justify-between items-center" },
      [
        _c(
          "vs-checkbox",
          {
            model: {
              value: _vm.hide,
              callback: function($$v) {
                _vm.hide = $$v
              },
              expression: "hide"
            }
          },
          [
            _vm._v(
              _vm._s(this.$t("apps.campaigns.section_submit_checkbox_hide"))
            )
          ]
        ),
        _c(
          "div",
          { staticClass: "flex flex-row items-baseline" },
          [
            _c("p", [
              _vm._v(
                _vm._s(this.$t("apps.campaigns.section_submit_device_count")) +
                  " " +
                  _vm._s(_vm.campaign_total_installations_count) +
                  " " +
                  _vm._s(this.$t("apps.campaigns.section_content_devices"))
              )
            ]),
            _vm.campaign_edit_mode
              ? _c(
                  "vs-button",
                  {
                    staticClass: "rounded-lg ml-4",
                    attrs: {
                      disabled: !_vm.campaign_is_valid(
                        _vm.hasNotificationsAllowed
                      ),
                      color: "primary",
                      type: "filled"
                    },
                    on: { click: _vm.editCampaign }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          this.$t("apps.campaigns.section_submit_button_save")
                        ) +
                        " "
                    )
                  ]
                )
              : _c(
                  "vs-button",
                  {
                    staticClass: "rounded-lg ml-4",
                    attrs: {
                      disabled: !_vm.campaign_is_valid(
                        _vm.hasNotificationsAllowed
                      ),
                      color: "primary",
                      type: "filled",
                      icon: "send",
                      "icon-after": true
                    },
                    on: { click: _vm.scheduleCampaign }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          this.campaign_send_now
                            ? this.$t(
                                "apps.campaigns.section_submit_button_send"
                              )
                            : this.$t(
                                "apps.campaigns.section_submit_button_program"
                              )
                        ) +
                        " "
                    )
                  ]
                )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }