var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isModalOpen,
          expression: "isModalOpen"
        }
      ]
    },
    [
      _c(
        "vs-popup",
        {
          attrs: { active: _vm.isModalOpen, title: "" },
          on: {
            "update:active": function($event) {
              _vm.isModalOpen = $event
            }
          },
          model: {
            value: _vm.isModalOpen,
            callback: function($$v) {
              _vm.isModalOpen = $$v
            },
            expression: "isModalOpen"
          }
        },
        [
          _c("div", { staticClass: "px-5" }, [
            _vm.isNew
              ? _c("h3", [
                  _vm._v(
                    " " + _vm._s(_vm.$t("apps.campaigns.form.createTitle"))
                  )
                ])
              : _vm._e(),
            !_vm.isNew
              ? _c("h3", [
                  _vm._v(" " + _vm._s(_vm.$t("apps.campaigns.form.editTitle")))
                ])
              : _vm._e()
          ]),
          _c(
            "div",
            { staticClass: "px-5 " },
            [
              _c(
                "h5",
                {
                  staticClass:
                    "text-sm text-base text-dark mt-4 mb-5 font-bold "
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("apps.campaigns.form.referenceTitle")) + " *"
                  )
                ]
              ),
              _c("InputCharCounter", {
                attrs: {
                  componentClases: "w-5/5",
                  ModelValue: _vm.category.reference,
                  maxLength: 100
                },
                on: {
                  input: function(value) {
                    _vm.category.reference = value
                  }
                }
              })
            ],
            1
          ),
          _c("div", { staticClass: " mt-5 px-5" }, [
            _c("h5", { staticClass: "text-base mt-1  mb-6 font-bold" }, [
              _vm._v(_vm._s(_vm.$t("apps.campaigns.form.languajeTitle")))
            ]),
            _c(
              "div",
              [
                _vm._l(_vm.languages, function(language, index) {
                  return _vm.isNew
                    ? _c("div", { key: index, staticClass: "w-2/2   mt-3 " }, [
                        _c(
                          "div",
                          { staticClass: "vx-row" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "w-full vx-col text-base font-medium "
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.adaptAcronyms(language.acronym))
                                )
                              ]
                            ),
                            _c("InputCharCounter", {
                              attrs: {
                                index: index,
                                componentClases: "w-full vx-col",
                                ModelValue:
                                  _vm.category.locales[language.acronym],
                                maxLength: 100
                              },
                              on: {
                                input: function(value) {
                                  _vm.category.locales[language.acronym] = value
                                }
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                }),
                _vm._l(_vm.category.locales, function(language, index) {
                  return _vm.isNew == false
                    ? _c("div", { key: index, staticClass: "w-2/2 " }, [
                        _c(
                          "div",
                          { staticClass: "vx-row" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "w-full vx-col text-base font-medium "
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.adaptAcronyms(language.language))
                                )
                              ]
                            ),
                            _vm._v(" " + _vm._s(language.acronym) + " "),
                            _c("InputCharCounter", {
                              attrs: {
                                index: index,
                                componentClases: "w-full vx-col",
                                ModelValue: language.title,
                                maxLength: 100
                              },
                              on: {
                                input: function(value) {
                                  language.title = value
                                }
                              }
                            })
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                })
              ],
              2
            )
          ]),
          _c(
            "div",
            [
              _c(
                "vs-checkbox",
                {
                  staticClass: "ml-4 mb-4 mt-4 text-base font-bold text-dark",
                  model: {
                    value: _vm.category.published,
                    callback: function($$v) {
                      _vm.$set(_vm.category, "published", $$v)
                    },
                    expression: "category.published"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("apps.campaigns.form.published")) + " ")]
              )
            ],
            1
          ),
          _c("div", { staticClass: "px-5" }, [
            _c(
              "div",
              { staticClass: "flex flex-row gap-4 mt-8" },
              [
                _c(
                  "vs-button",
                  {
                    staticClass: "rounded-lg  w-1/2 bg-white text-primary",
                    attrs: { type: "border" },
                    on: {
                      click: function($event) {
                        return _vm.close()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("apps.campaigns.form.cancelButton")) +
                        " "
                    )
                  ]
                ),
                _vm.isNew
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "rounded-lg  w-1/2 hover:bg-grey",
                        on: {
                          click: function($event) {
                            return _vm.save()
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("apps.campaigns.form.createButton")) +
                            " "
                        )
                      ]
                    )
                  : _vm._e(),
                !_vm.isNew
                  ? _c(
                      "vs-button",
                      {
                        staticClass: "rounded-lg  w-1/2 hover:bg-grey",
                        on: {
                          click: function($event) {
                            return _vm.save()
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("apps.campaigns.form.saveButton")) +
                            " "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }