var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h5", { staticClass: "mb-4" }, [
      _vm._v(_vm._s(this.$t("apps.campaigns.section_cta_title")))
    ]),
    _c(
      "div",
      { staticClass: "vx-row pl-4" },
      [
        _c("v-select", {
          staticClass: "w-1/4",
          attrs: {
            clearable: false,
            options: _vm.isSkiApp
              ? _vm.campaigns.form_options.cta
              : _vm.campaigns.form_options.cta.filter(function(option) {
                  return option.code !== "challenge"
                })
          },
          model: {
            value: _vm.selectedCta,
            callback: function($$v) {
              _vm.selectedCta = $$v
            },
            expression: "selectedCta"
          }
        }),
        _vm.selectedCta.code === "challenge"
          ? _c("v-select", {
              staticClass: "w-1/4 ml-2",
              attrs: { clearable: false, options: _vm.challenges },
              model: {
                value: _vm.selectedChallenge,
                callback: function($$v) {
                  _vm.selectedChallenge = $$v
                },
                expression: "selectedChallenge"
              }
            })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }