<template>
    <div v-show="isModalOpen">
        <vs-popup   v-model="isModalOpen" :active.sync="isModalOpen" title="" > 
            <div class="px-5" >
                <h3 v-if=isNew> {{ $t('apps.campaigns.form.createTitle') }}</h3>        
                <h3 v-if=!isNew> {{ $t('apps.campaigns.form.editTitle') }}</h3>        
            </div>
        
            <div class="px-5 " >
                <h5  class="text-sm text-base text-dark mt-4 mb-5 font-bold ">{{ $t('apps.campaigns.form.referenceTitle') }} *</h5>
                <InputCharCounter
                   componentClases="w-5/5"
                   :ModelValue="category.reference"
                   :maxLength=100
                   @input = "(value) => { category.reference = value }"
                ></InputCharCounter>
                
            </div>    
            
            <div class=" mt-5 px-5" >
                <h5  class="text-base mt-1  mb-6 font-bold">{{ $t('apps.campaigns.form.languajeTitle') }}</h5>
                    <div >
                        <div v-if="isNew" v-for="(language, index) in languages"   :key="index" class="w-2/2   mt-3 ">
                            <div class="vx-row">
                                <p class="w-full vx-col text-base font-medium "> {{adaptAcronyms(language.acronym)}}</p>
                                
                                <InputCharCounter
                                    :index="index"
                                    componentClases="w-full vx-col"
                                    :ModelValue="category.locales[language.acronym]"
                                    :maxLength=100
                                    @input = "(value) => { category.locales[language.acronym] = value }"
                                 ></InputCharCounter>
                            </div>
                        </div>
                  

                        <div v-if="isNew == false" v-for="(language, index) in category.locales"   :key="index" class="w-2/2 ">

                            <div class="vx-row">
                                <p class="w-full vx-col text-base font-medium ">{{adaptAcronyms(language.language)}}</p>
                                {{ language.acronym }}
                                <InputCharCounter
                                    :index="index"
                                    componentClases="w-full vx-col"
                                    :ModelValue="language.title"
                                    :maxLength=100
                                    @input = "(value) => { language.title = value }"
                                 ></InputCharCounter>

                            </div>                            
                        </div>                        
                    </div>
            </div>
               
            <div>
                <vs-checkbox        
                    class="ml-4 mb-4 mt-4 text-base font-bold text-dark" 
                    v-model="category.published"
                    >{{ $t('apps.campaigns.form.published') }} 
                </vs-checkbox>        
            </div>
            
            <div class="px-5">
                <div class="flex flex-row gap-4 mt-8">
                    <vs-button 
                        @click="close()"
                        type="border"                                    
                        class="rounded-lg  w-1/2 bg-white text-primary" >
                        {{ $t('apps.campaigns.form.cancelButton') }}
                    </vs-button>
                    <vs-button v-if=isNew               
                        @click="save()"
                        class="rounded-lg  w-1/2 hover:bg-grey" >
                        {{ $t('apps.campaigns.form.createButton') }}
                    </vs-button>
                    <vs-button v-if=!isNew                
                        @click="save()"
                        class="rounded-lg  w-1/2 hover:bg-grey" >
                        {{ $t('apps.campaigns.form.saveButton') }}
                    </vs-button>
                </div>          
            </div>
            
        </vs-popup> 
        </div>
        
</template>

<script>
import CampaignCategoriesService from "@/modules/Apps/Services/CampaignCategoriesService";
import InputCharCounter from "@/modules/Shared/Components/form/InputCharCounter.vue";

export default {
    name: 'CreateOrEditCategoryForm',
    components:{
        InputCharCounter
    },
    props: {
        selectedCategory: {
            type: Object,
            required: true,
        },
        languages: {
            type: Array,
            required: true,
        },
        isNew: {
            type: Boolean,
            required: true,
        },
        aplicationUuid: {
            type: String,
            required: true,
        },
        isModalOpen: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        reference() { return this.category.reference ? this.category.reference.length : 0; },
    },
    created() {
        this.category = { ...this.selectedCategory };
    },
    data() {
        return {
            category: {},
        };
    },
    watch: {
        isModalOpen(newValue) {
            if (newValue == false)
                this.$emit('modalToFalse');
        }
    },
    methods: {
        adaptAcronyms(acronym){
            return this.$t(`idiomas.${acronym}`)
        },
        close() {
            this.isModalOpen = false;
        },
        async save() {
            if (this.isNew) {
                let errors = this.checkHasAllLanguages();
                if (errors.length > 0) {
                    this.$vs.notify({
                        type: 'confirm',
                        title: "Error",
                        text: this.$t("apps.campaigns.form.errorAlert") + errors,
                        color: "danger",
                        position: "top-right"
                    });
                    return;
                }
                let locales = [];
                for (const key in this.category.locales) {
                    locales.push({ language: key, title: this.category.locales[key] });
                }
                let emptyFields = this.checkFieldsAreNotEmpty(locales);
                if (emptyFields.length > 0) {
                    this.$vs.notify({
                        type: 'confirm',
                        title: "Error",
                        text: this.$t("apps.campaigns.form.emptyFieldsAlert") + emptyFields,
                        color: "danger",
                        position: "top-right"
                    });
                    return;
                }
                let data = {
                    reference: this.category.reference,
                    application_uuid: this.aplicationUuid,
                    published: this.category.published,
                    locales: JSON.stringify(locales)
                };
                await CampaignCategoriesService.saveCategory(this.category.uuid, data);
                this.isModalOpen = false;
                this.$emit('updateList');
            }
            else {
                let emptyFields = this.checkFieldsAreNotEmpty(this.category.locales);
                if (emptyFields.length > 0) {
                    this.$vs.notify({
                        type: 'confirm',
                        title: "Error",
                        text: this.$t("apps.campaigns.form.emptyFieldsAlert") + emptyFields,
                        color: "danger",
                        position: "top-right"
                    });
                    return;
                }
                let data = {
                    reference: this.category.reference,
                    application_uuid: this.aplicationUuid,
                    published: this.category.published,
                    locales: JSON.stringify(this.category.locales)
                };
                await CampaignCategoriesService.updateCategory(this.category.uuid, data);
                this.isModalOpen = false;
                this.$emit('updateList');
            }
        },
        checkHasAllLanguages() {
            let languagesMissing = [];
            let filledLanguages = Object.keys(this.category.locales);
            this.languages.map(item => {
                if (!filledLanguages.includes(item.acronym)) {
                    languagesMissing.push(this.adaptAcronyms(item.acronym));
                }
            });
            return languagesMissing;
        },
        checkFieldsAreNotEmpty(locales) {
            let errors = [];
            if (this.category.reference == "") {
                errors.push('Reference');
            }
            for (const key in locales) {
                if (locales[key].title == "") {
                    errors.push(this.adaptAcronyms(locales[key].language));
                }
            }
            return errors;
        },
        checkItemLength(item) { return item === undefined ? 0 : item.length; }
    },
}
</script>