<template>
  <div v-if="campaign_is_segmented_by_selected_users || campaign_selected_languages.length">
    <h5 class="mb-0">{{ this.$t('apps.campaigns.section_content_title') }}</h5>
    <div v-if="campaign_selected_languages.length" class="flex flex-row flex-wrap">
      <CampaignContent
        v-for="language in campaign_selected_languages"
        :language="language"
        :key="language.acronym"
      />
    </div>
    <div v-else class="vx-row pl-4">
      <CampaignContent/>
    </div>
  </div>
</template>

<script>

import CampaignContent from '@/modules/Apps/Components/campaigns/form/CampaignContent'
import {mapGetters} from "vuex";

export default {
    name: 'campaign-contents',
    components: {
      CampaignContent,
    },
    computed: {
      ...mapGetters('apps', ['campaign_selected_languages', 'campaign_is_segmented_by_selected_users']),
    },
}
</script>
